import React, {
  FunctionComponent,
  useState,
  useRef,
  useEffect,
  useMemo
} from 'react';
import styles from './blog-categories-menu.module.scss';
import { useGlobalTranslations } from '../../../hooks/use-global-translations';
import { ICategoryModel } from '../../../models/category-model';
import { blogCategoryUrl } from '../../../utils/utils';
import { InternalLink } from '../../internal-link/internal-link';
import debounce from 'lodash/debounce';
import { navbarHeight } from '../../../const/settings';
import { BLOG_URL } from '../../../const/url';

export interface IBlogCategoriesMenuProps {
  categories: ICategoryModel[];
  currentUrl: string;
}

const options = {
  threshold: [0.0, 0.3, 0.5, 0.7]
};

export const BlogCategoriesMenu: FunctionComponent<IBlogCategoriesMenuProps> = ({
  categories,
  currentUrl
}) => {
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const [displayMenu, setDisplayMenu] = useState<boolean>(true);
  const { all } = useGlobalTranslations();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(toggleMenuHanlder, options);
    const hideMenuSection = document.getElementById('hide-categories-menu');
    const debouncedStickyHandler = debounce(stickyHandler, 100);

    hideMenuSection && observer.observe(hideMenuSection);
    document.addEventListener('scroll', debouncedStickyHandler);

    return (): void => {
      document.removeEventListener('scroll', debouncedStickyHandler);
    };
  }, []);

  const stickyHandler = (): void => {
    const menu = ref.current;
    const menuPostion = menu?.getBoundingClientRect().top;

    menuPostion && menuPostion <= navbarHeight
      ? setIsSticky(true)
      : setIsSticky(false);
  };

  const isAllCategory = useMemo(() => {
    const categoriesUrls = categories.map(category => category.categoryUrl);
    let isAll = true;

    for (let index = 0; index < categoriesUrls.length; index++) {
      if (currentUrl.includes(categoriesUrls[index])) {
        isAll = false;
        break;
      }
    }

    return isAll;
  }, [categories, currentUrl]);

  const toggleMenuHanlder = (entries): void => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        if (entry.intersectionRatio > 0.4) {
          setDisplayMenu(false);
        } else {
          setDisplayMenu(true);
        }
      }
    });
  };

  return (
    <React.Fragment>
      {displayMenu && (
        <div className={`${styles.categories}`} ref={ref}>
          <div
            className={`${styles.categoriesWrapper} ${isSticky &&
              styles.sticky}`}
          >
            <div className={styles.container}>
              <ul className={styles.categoriesList}>
                <li className={styles.category}>
                  <InternalLink
                    activeClassName={isAllCategory ? styles.active : ''}
                    className={styles.categoryName}
                    url={BLOG_URL}
                  >
                    {all}
                  </InternalLink>
                </li>
                {categories.map((category: ICategoryModel) => (
                  <li className={styles.category} key={category.categoryName}>
                    <InternalLink
                      className={styles.categoryName}
                      activeClassName={styles.active}
                      url={blogCategoryUrl(category.categoryUrl)}
                    >
                      {category.categoryName}
                    </InternalLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
